import {
  ComponentPropsCollection,
  ComponentPropsContext,
  ErrorPages,
  LayoutServiceData,
  SitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { componentBuilder } from 'temp/componentBuilder';
import Layout from 'src/Layout';
import { useEffect, useState } from 'react';
import { GetStaticProps } from 'next';
import SitecoreSetAllDiscoverRulesCover from 'lib/configurations/sitecoreSetAllDiscoverRulesCover';
import OrderCloudSearchProvider from 'lib/configurations/sitecoreOrderCloudSearchProvider';
import ComponentContext, { ComponentContextData } from 'lib/context/ComponentContext';
import { SiteName } from 'src/helpers/Constants';
import NotFound from 'src/NotFound';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import OcProvider from 'src/redux/ocProvider';
import { SitecorePageProps } from 'lib/page-props';
import { ComponentProps } from '@sitecore-feaas/clientside';
import { CheckoutFormContextProvider } from 'lib/context/CheckoutFormContext';
import { HeaderContextProvider } from 'lib/context/HeaderComponentContext';
import { Cookies } from 'react-cookie';
import MaintenanceMode from 'components/MaintainanceMode/MaintainanceMode';

type PageProps = SitecorePageProps & {
  data: ComponentPropsCollection;
};

const Custom404: React.FC<PageProps> = () => {
  const [layoutData, setLayoutData] = useState<LayoutServiceData>();
  const [componentProps, setComponentProps] = useState<ComponentProps>();
  const [fetchError, setFetchError] = useState(false);
  const [componentContextData, setcomponentContextData] = useState<ComponentContextData>({});
  const cookies = new Cookies();
  const isMaintainanceMode = process.env.NEXT_PUBLIC_MAINTAINANCE_MODE === 'true';

  useEffect(() => {
    // Only execute if we didn't get data from the default.
    // Depending on requirements, we may want to always fetch.
    if (!layoutData) {
      fetch(`/api/error/content?storeId=${cookies.get('storeId')}`)
        .then(async (res) => {
          const data = (await res.json()) as ErrorPages | null;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((data as any)?.layoutData) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setComponentProps((data as any)?.componentProps);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setLayoutData((data as any)?.layoutData);
            window.history.replaceState(null, '', '/404');
          } else {
            // There was no custom error page, render the fallback.
            setFetchError(true);
          }
        })
        .catch(() => {
          // There was an error, render the fallback.
          setFetchError(true);
        });
    }
    // We can't use layout data as a dependency because we are updating it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMaintainanceMode) {
    return <MaintenanceMode />;
  }

  if (fetchError) {
    return <NotFound />;
  }

  if (!layoutData || !componentProps) {
    return <></>;
  }

  return (
    <ComponentPropsContext value={componentProps}>
      <SitecoreContext
        componentFactory={componentBuilder.getComponentFactory()}
        layoutData={layoutData}
      >
        <OcProvider>
          <OrderCloudSearchProvider
            siteName={layoutData?.sitecore?.context?.site?.name as SiteName}
          >
            <HeaderContextProvider>
              <ComponentContext.Provider
                value={{
                  componentContextData,
                  setcomponentContextData,
                }}
              >
                <CheckoutFormContextProvider>
                  <Layout layoutData={layoutData} headLinks={[]} />
                </CheckoutFormContextProvider>
              </ComponentContext.Provider>
            </HeaderContextProvider>
          </OrderCloudSearchProvider>
          <SitecoreSetAllDiscoverRulesCover />
        </OcProvider>
      </SitecoreContext>
    </ComponentPropsContext>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  if (process.env.DISABLE_SSG_FETCH || context?.locale?.toLocaleLowerCase() === 'default') {
    return { props: {} };
  }

  // We can call layout service directly here unlike in _error since this only runs on the server
  const props = await sitecorePagePropsFactory.create({
    params: {
      path: ['_404'],
    },
    locale: context?.locale,
  });

  return { props };
};

export default Custom404;
